
import { defineComponent, PropType, reactive, ref } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'

import CommonSwitchFilter from '@/components/common/CommonSwitchFilter.vue'
import CommonDateInput from '@/components/common/CommonDateInput.vue'
import CommonNumberInput from '@/components/common/CommonNumberInput.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { FilterField } from '@/composables/types/useFilter'
import { FILTER_FIELD_KEY } from '@/views/planning/views/workforcePlanning/types'

export default defineComponent({
  name: 'ComparisonFilterBar',
  components: {
    CommonSwitchFilter,
    CommonDateInput,
    CommonNumberInput,
    CommonAutocomplete,
  },
  props: {
    fields: {
      type: Array as PropType<FilterField[]>,
      required: true,
    },
  },
  setup: (props) => {
    function getFilterByKey(key: FILTER_FIELD_KEY): FilterField {
      return props.fields.find((field) => field.key === key) as FilterField
    }

    const profUnitWithChildren = ref(true)

    function onToggleProfUnitWithChildren(): void {
      if (profUnitWithChildren.value) {
        const inactiveFilterFieldKey = FILTER_FIELD_KEY.ProfessionalUnitIdsWithChildren
        const inactiveFilter = getFilterByKey(inactiveFilterFieldKey)

        const activeFilterFieldKey = FILTER_FIELD_KEY.ProfessionalUnitIds
        const activeFilter = getFilterByKey(activeFilterFieldKey)

        activeFilter.value = inactiveFilter.value
        inactiveFilter.value = null
      } else {
        const inactiveFilterFieldKey = FILTER_FIELD_KEY.ProfessionalUnitIds
        const inactiveFilter = getFilterByKey(inactiveFilterFieldKey)

        const activeFilterFieldKey = FILTER_FIELD_KEY.ProfessionalUnitIdsWithChildren
        const activeFilter = getFilterByKey(activeFilterFieldKey)

        activeFilter.value = inactiveFilter.value
        inactiveFilter.value = null
      }

      profUnitWithChildren.value = !profUnitWithChildren.value
    }

    const orgUnitWithChildren = ref(true)

    function onToggleOrgUnitWithChildren(): void {
      if (orgUnitWithChildren.value) {
        const inactiveFilterFieldKey = FILTER_FIELD_KEY.OrganizationalUnitIdsWithChildren
        const inactiveFilter = getFilterByKey(inactiveFilterFieldKey)

        const activeFilterFieldKey = FILTER_FIELD_KEY.OrganizationalUnitIds
        const activeFilter = getFilterByKey(activeFilterFieldKey)

        activeFilter.value = inactiveFilter.value
        inactiveFilter.value = null
      } else {
        const inactiveFilterFieldKey = FILTER_FIELD_KEY.OrganizationalUnitIds
        const inactiveFilter = getFilterByKey(inactiveFilterFieldKey)

        const activeFilterFieldKey = FILTER_FIELD_KEY.OrganizationalUnitIdsWithChildren
        const activeFilter = getFilterByKey(activeFilterFieldKey)

        activeFilter.value = inactiveFilter.value
        inactiveFilter.value = null
      }

      orgUnitWithChildren.value = !orgUnitWithChildren.value
    }

    return reactive({
      icons: {
        mdiMagnify,
      },
      constants: {
        FILTER_FIELD_KEY,
      },
      state: {
        profUnitWithChildren,
        orgUnitWithChildren,
      },
      functions: {
        getFilterByKey,
      },
      listeners: {
        onToggleProfUnitWithChildren,
        onToggleOrgUnitWithChildren,
      },
    })
  },
})
